/**
 * Created by vaibhav on 2/4/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Link from "gatsby-link"
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from "react-accessible-accordion"
import IRPageWrapper from "../../../layouts/IRPageWrapper"
import IRNav from "../../../components/IRNav"
import IRBG from "../../../components/IRBG"
import HomeLogo from "../../../components/HomeLogo"

import "react-accessible-accordion/dist/minimal-example.css"
import "react-accessible-accordion/dist/fancy-example.css"

class KeyManagementPage extends Component {
  render() {
    return (
      <IRPageWrapper>
        <IRBG />
        <Helmet>
          <title>General Corporate Information</title>
        </Helmet>
        <section className="hero is-small">
          <div className="columns">
            <div className="column is-offset-1">
              <div className="section">
                <HomeLogo />
                <h1 className="title" id="title-line">
                  Key Management
                </h1>
                <IRNav />
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="column is-8 is-offset-1">
            <Accordion>
              <AccordionItem>
                <AccordionItemTitle>
                  <p className="u-position-relative">
                    Nasrul Asni Muhammad Dain
                    <div className="accordion__arrow" role="presentation" />
                  </p>
                  <h3>Chief Executive Officer</h3>
                </AccordionItemTitle>
                <AccordionItemBody>
                  <p>
                    Nasrul Asni Muhammad Dain, a Malaysian, is our Chief Executive Officer. He graduated from University of Wales, Cardiff, United Kingdom with a Bachelor Degree (Hons) in Accounting & Finance. He was also a Certified Credit Professional of the Institut Bank-Bank Malaysia. He started his career at Kewangan Industri Berhad as Loan Officer in 1996. In 1999 till 2006, he joined Bank Industri (M) Berhad as a Senior Executive. Then he was promoted to Assistant Manager, Group Special Assets Division in 2001 and as Manager, Rehabilitation Department & Acting Head of Department in 2005.  He also served in OCBC Bank Malaysia from 2007 till 2009 as Head of Shipping Department. Prior joining E.A. Technique (M) Berhad, he was a Head / Senior Vice President at RHB Bank Berhad in 2009 till 2022. 
                  </p>
                </AccordionItemBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemTitle>
                  <p className="u-position-relative">
                    Mariam Puan
                    <div className="accordion__arrow" role="presentation" />
                  </p>
                  <h3>Chief Financial Officer</h3>
                </AccordionItemTitle>
                <AccordionItemBody>
                  <p>
                    Mariam Puan, a Malaysian, is our Chief Financial Officer. She 
                    is a graduate of the Association of Chartered Certified 
                    Accountants, United Kingdom. She is also a member of the 
                    Malaysian Institute of Accountants. She started her career at 
                    Bashir Bhatti Accounting Services (Birmingham, UK) in 1989. 
                    She also served in various capacity in Sime Engineering Sdn 
                    Bhd from 1990 till 1995. She was a Finance & Administration 
                    Manager at HICOM Environment Sdn Bhd from 1995 till 1998. 
                    Then she joined Alam Flora Sdn Bhd in 1998, working in 
                    various capacity including as Chief Financial Officer till 2009. 
                    In 2010 till 2012, she was a Chief Financial Officer at Glen 
                    Marie Properties Sdn Bhd. In 2012, she was the Chief Financial 
                    Officer at Glen Marie Assets Sdn Bhd. Prior joining E.A 
                    Technique (M) Berhad, she was a General Manager (Group Financial Services) 
                    at DRB HiCOM Berhad (2014-2018) and in 2018, she was the 
                    Deputy Chief Financial Officer of Prasarana Malaysia Berhad.
                  </p>
                </AccordionItemBody>
              </AccordionItem>
              
            </Accordion>
          </div>
        </section>
      </IRPageWrapper>
    )
  }
}

KeyManagementPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default KeyManagementPage
